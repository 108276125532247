import { Tenant } from "@davi-ai/retorik-map";

const URL_API_TENANT = "https://dev.management.retorik.ai/api/getAgents";
const URL_TOKEN_API = "https://auth.retorik.ai/oidc/token";
const API_CLIENT_ID = "asklea";
const API_CLIENT_SERCRET = "388D45FA-B36B-4988-BA59-B187D329C207";

const getToken = async (): Promise<any> => {
  const token = (
    await fetch(URL_TOKEN_API, {
      method: "POST",
      body: `grant_type=client_credentials&scope=profile&client_id=${API_CLIENT_ID}&client_secret=${API_CLIENT_SERCRET}`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
  ).json();
  return token;
};

const getTenant = async (): Promise<{ [key: string]: Tenant }> => {
  const token = await getToken();
  const tenants: any = await (
    await fetch(URL_API_TENANT, {
      method: "GET",
      //body: `grant_type=client_credentials&scope=profile&client_id=${API_CLIENT_ID}&client_secret=${API_CLIENT_SERCRET}`,
      headers: {
        authorization: `Bearer ${token.access_token}`,
      },
    })
  ).json();
  const res: { [key: string]: Tenant } = {};
  console.log("tenants", tenants);
  if (tenants && tenants.length > 0) {
    const filteredData = tenants.filter((a: any) => a.name === "Johan");
    filteredData.forEach((agent: any) => {
      res[agent.name] = {
        name: agent.name,
        retorikName: agent.name,
        areaLocation: agent.agentZone?.zone,
      };
    });
  }
  return res;
};

export { getTenant };

import { Tenant } from "@davi-ai/retorik-map";
import { LatLngExpression, LatLngLiteral } from "leaflet";
import * as turf from "@turf/turf";

const checkIfGeolocationIsInParis = (
  tenantParis: Tenant,
  userLocation: LatLngLiteral
): boolean => {
  if (tenantParis && tenantParis.areaLocation) {
    let polygon: LatLngExpression[][] = tenantParis.areaLocation;
    return isMarkerInsidePolygon(userLocation, polygon);
  }
  return false;
};

function HandleMultiplePolygon(polygon, currentLoc): boolean {
  let boolIsIn = false;
  polygon.geometry.coordinates.forEach((arrayCoord) => {
    if (polygon.geometry.type === "MultiPolygon") {
      arrayCoord.forEach((subarrayCoord) => {
        const constructedPoly = turf.polygon([subarrayCoord]);
        const isPolyInside = turf.booleanPointInPolygon(
          currentLoc,
          constructedPoly,
          { ignoreBoundary: true }
        );
        console.log("isPolyInside", constructedPoly, isPolyInside);
        if (isPolyInside) boolIsIn = true;
      });
    } else {
      if (arrayCoord.length >= 4) {
        const constructedPoly = turf.polygon([arrayCoord]);
        const isPolyInside = turf.booleanPointInPolygon(
          currentLoc,
          constructedPoly,
          { ignoreBoundary: true }
        );
        console.log("isPolyInside", constructedPoly, isPolyInside);
        if (isPolyInside) boolIsIn = true;
      } else {
        console.log("arrayCoord too small", arrayCoord?.length);
      }
    }
  });
  return boolIsIn;
}

function isMarkerInsidePolygon(
  cityLocation: LatLngLiteral,
  tenantPolygon: Array<Array<LatLngExpression>>
): boolean {
  let boolIsIn = false;
  const concavePolygonThin = turf.multiPolygon([
    tenantPolygon.map((zone) => {
      const coords = zone.map((a) => [a[1], a[0]]);
      // Close polygon
      if (coords.length > 0) {
        coords.push(coords[0]);
      }
      return coords;
    }),
  ]);
  const currentLoc = turf.point([
    parseFloat(cityLocation.lng + ""),
    parseFloat(cityLocation.lat + ""),
  ]);
  if (concavePolygonThin) {
    boolIsIn = HandleMultiplePolygon(concavePolygonThin, currentLoc);
  }
  return boolIsIn;
}

export { checkIfGeolocationIsInParis };

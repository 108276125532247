const botEventHandler: any = (action: any, _dispatch?: any): boolean => {
  //console.log(action, dispatch);
  if (!action) {
    return false;
  }
  if (
    action.type === "DIRECT_LINE/POST_ACTIVITY_FULFILLED" &&
    action.payload.activity.type === "event" &&
    action.payload.activity.name === "setUser"
  ) {
    // Bot Started
    // Inject CSS to Shadow
    const EVENT_INJECT_CSS = new CustomEvent("CustomEvent", {
      detail: {
        name: "InjectCSS",
      },
    });
    document.dispatchEvent(EVENT_INJECT_CSS);
  }

  if (
    action.type === "DIRECT_LINE/POST_ACTIVITY_FULFILLED" &&
    action?.payload?.activity?.value?.name === "hospitality.showPoiById"
  ) {
    const EVENT_START_LOADER = new CustomEvent("CustomEvent", {
      detail: {
        name: "ToggleLoader",
      },
    });
    document.dispatchEvent(EVENT_START_LOADER);
  }

  if (
    action.type === "DIRECT_LINE/INCOMING_ACTIVITY" &&
    action.payload.activity.type === "event" &&
    action.payload.activity.name === "hospitality.ShowPointOfInterest"
  ) {
    const EVENT_SELECT_POI_FROMBOT = new CustomEvent("CustomEvent", {
      detail: {
        name: "SelectPoiFromBot",
        value: action.payload.activity.value.identifier,
      },
    });
    console.log("Send EVENT", EVENT_SELECT_POI_FROMBOT);
    document.dispatchEvent(EVENT_SELECT_POI_FROMBOT);
  }
  if (
    action.type === "DIRECT_LINE/POST_ACTIVITY_FULFILLED" &&
    action.payload.activity?.value?.name === "hospitality.showPoiById"
  ) {
    // Handle activity sho detail finished
    const EVENT_SHOW_POI_FINISHED = new CustomEvent("CustomEvent", {
      detail: {
        name: "ShowPOIFinished",
      },
    });
    console.log("Send EVENT", EVENT_SHOW_POI_FINISHED);
    document.dispatchEvent(EVENT_SHOW_POI_FINISHED);
  }

  return false;
};

export { botEventHandler };

import {
  PonyfillFactoryCredentials,
  RetorikThemeColors,
  UserData,
} from '@davi-ai/retorik-framework'
// import TestPage from './TestPage'

const userData: UserData = {
  name: '',
  id: Math.random().toString(36).substring(2, 9),
  username: '',
  nom: '',
  prenom: '',
  email: '',
  token: '',
  referrer: '',
  ipaddress: ''
}

const ponyfillFactoryCredentials: PonyfillFactoryCredentials = {
  region: 'francecentral',
  subscriptionKey: '2f62867e22a544b78a16c99a2707ef49'
}

const voices = {
  guy: {
    voice: 'GuyNeural'
  },
  henri: {
    voice: 'HenriNeural'
  },
  multiMale:{
    voice:"en-US-RyanMultilingualNeural"
  },
  multi: {
    voice: 'EmmaMultilingualNeural'
  },
  female: {
    gender: 'female' as any 
  }
}

const mockPlainAttachment = {
  Artwork: {
    Category: {
      Values: ["Objets d'Art"]
    },
    Situation: {
      Text: 'Non exposé'
    },
    Favourite: {
      Value: false
    },
    NoticeURL: {
      Text: 'https://musees-reims.fr/oeuvre/chapelle-monju-bostatsu'
    },
    Image: {
      Paths: [
        'https://musees-reims.fr/local/cache-vignettes/L862xH1024/2546fed5-3c4e-47ba-8205-fb4925f51567-c7436.jpg?1693576378'
      ],
      MediaTexts: []
    },
    ArtworkDesignation: {
      Text: 'Chapelle : Monju Bostatsu'
    },
    Observations: {
      Markdown:
        'Sur la porte de gauche est inscrite la dédicace au défunt : « Offert par la famille Matsudaira pour Eish?in [My?ju Hinobe ?], avec ses prières ». Sur la porte de droite sont inscrits des caractères Bonji, et dans la partie inférieure le nom de la divinité : Monj? shiri b?satsu (??????). Le Boddhisattva Manjushri (Monj? [shiri] au Japon), connu pour sa sagesse, signifie « Gloire paisible » en sanskrit. Il est représenté tenant le glaive de la Sagesse de la main droite (pour trancher les illusions), et le rouleau du sutra Hannyaky? (Perfection de la Sagesse – Prajnaparamita S?tra) de la main gauche. Il est assis sur le lion symbolisant la force de la Loi bouddhique. [G. Magnier, 2018]'
    },

    AuthorName: {
      Text: 'ANONYME JAPONAIS 18ème siècle'
    },
    AuthorFirstname: {
      Text: null
    },
    ArtworkPeriodDate: {
      Markdown: '18e siècle'
    },
    Libelle: {
      Text: 'Bois, laque, laiton'
    },
    Measures: {
      Text: 'H x L x P en cm : 29 x 19,5 x 15'
    },

    Place: {
      Markdown: 'Japon | Asie orientale | Asie'
    },
    Notes: {
      Markdown: null
    },
    AdditionalMeasures: {
      Text: 'Hauteur en cm : 29'
    },
    Exhibition: {
      Text: 'Le Goût du Japon. Voyages et collections à l’ère Meiji Musée Saint-Remi 05/10/2018 13/01/2019'
    },
    Collection: {
      Text: 'GÉRARD Alfred'
    },
    Movement: {
      Text: 'Japon'
    },
    Institution: {
      Values: ['Musée Saint-Remi']
    },
    Photographer: {
      Text: 'Photo : Christian Devleeschauwer'
    },
    RightfulClaimant: {
      Text: null
    },
    ArticleURLLink: {
      Text: 'https://musees-reims.fr/fr/musee-numerique/expositions-thematiques/'
    },
    SubjectThemeRecto: {
      Markdown:
        'Manjusrî ;bodhisattva ;assis en tailleur ;trône de lotus ;lion ;couché ;glaive ;rouleau ;auréole ;ornement à forme végétale ;couronne ;collier '
    },
    SubjectThemeVerso: {
      Markdown: null
    },
    Domain: {
      Text: 'Asie orientale;croyances - coutumes'
    },
    SimplifiedObservations: {
      Markdown:
        'Sur la porte de gauche est inscrite la dédicace au défunt : « Offert par la famille Matsudaira pour Eish?in [My?ju Hinobe ?], avec ses prières ». Sur la porte de droite sont inscrits des caractères Bonji, et dans la partie inférieure le nom de la divinité : Monj? shiri b?satsu (??????). Le Boddhisattva Manjushri (Monj? [shiri] au Japon), connu pour sa sagesse, signifie « Gloire paisible » en sanskrit. Il est représenté tenant le glaive de la Sagesse de la main droite (pour trancher les illusions), et le rouleau du sutra Hannyaky? (Perfection de la Sagesse – Prajnaparamita S?tra) de la main gauche. Il est assis sur le lion symbolisant la force de la Loi bouddhique. [G. Magnier, 2018]'
    },
    ButtonTitle: {
      Text: 'Nos expositions thématiques'
    }
  },
  locale: 'fr-FR',
  isUsedOnBorne: false,
  baseAddress: '',
  sendPostBack: (value: string) => console.log('Send post back : ', value)
}

const colorOnLight = "#002D73";
const colorOnDark = "#2ca6ea";
const commonColors = {
  primary: '#135AA5',
  secondary: colorOnLight,
  black: '#101219',
  whereToEatColor: '#FC952E',
  whereToSleepColor: '#9F007D',
  tobeSeenColor: '#76AB2A',
  tobeDoneColor: '#E23C2A',
  localProductsColor: '#AFB942',
  servicesColor: '#00C8BC',
  agendaColor: '#865DA2'
}

const colors : RetorikThemeColors = {
  ...commonColors,
  theme:"dark",
  companyName: '#000',
  loader: {
    animation: colorOnDark,
    background: 'rgba(18,18,18,0.7)',
    text: '#FFF',
    toggle: {
      text: '#000',
      background: colorOnDark
    },
    button: {
      background: {
        default: 'transparent',
        hover: 'transparent'
      },
      border: {
        default: colorOnDark,
        hover: '#FFF'
      },
      text: {
        default: colorOnDark,
        hover: '#FFF'
      }
    }
  },
  card: {
    frame: {
      background: colorOnLight,
      border: 'transparent',
      text: '#F7F3F6'
    },
    button: {
      background: {
        default: 'transparent',
        hover: 'transparent'
      },
      border: {
        default: colorOnDark,
        hover: '#FFF'
      },
      text: {
        default: colorOnDark,
        hover: '#FFF'
      }
    }
  },
  textMode: {
    panel: {
      background: 'rgba(255, 255, 255, 0.9)',
      border: 'transparent',
      conversationUser: '#101219',
      conversationBot: commonColors.primary
    }
  },
  vocalMode: {
    subtitles: {
      text: '#FFF',
      background: '#000'
    }
  },
  formInput: {
    text: {
      default: colorOnLight,
      hover: '#FFF'
    },
    inputRadioCheckbox: {
      unchecked: {
        background: '#434141',
        border: '#818181'
      },
      checked: {
        background: commonColors.primary,
        border: commonColors.primary,
        item: '#000'
      }
    },
    inputButton: {
      background: {
        default: commonColors.black,
        hover: commonColors.primary
      },
      border: {
        default: commonColors.primary,
        hover: '#FFF'
      },
      text: {
        default: '#FFF',
        hover: '#FFF'
      }
    },
    likert: {
      default: 'transparent',
      hover: '#E8B712'
    }
  }
}

export {
  userData,
  ponyfillFactoryCredentials,
  voices,
  mockPlainAttachment,
  colors
}

/* eslint-disable @typescript-eslint/no-unused-vars */
import '@ionic/react/css/core.css'
import React, {useEffect, useMemo, useState} from "react";

import {
  RetorikAgent,
  AvailableViews,
  sendActivity,
  AvailableSubViews,
  characters
} from "@davi-ai/retorik-framework";
import type {
  ChatbotData,
  Configuration,
  ViewsConfiguration,
  RetorikMainComponentConfiguration,
  PonyfillFactoryCredentials,
  RetorikMenusConfiguration,
  UserData,
  AddressData,
} from "@davi-ai/retorik-framework";
import { MapIcon, Map, Tenant, MapSettings, POIType } from "@davi-ai/retorik-map";
import { buildCustomMenu } from "./utils/customJOMenu";
import { botEventHandler } from "./components/CustomBotEventHandler";
import { getTenant } from "./utils/tenantService";
import { getDefaultLocalisationForJO } from "./utils/globalUtils";
import { checkIfGeolocationIsInParis } from "./utils/gpsUtils";
import { setupIonicReact } from '@ionic/react'
import { voices } from './data';

setupIonicReact()

const App = () => {
  const [_position, setPosition] = useState<{lat:number, lng:number} | undefined>(undefined)
  const [_selectedTenant, setSelectedTenant] = useState<Tenant|undefined>(undefined);
  const [_tenantList, setTenantList] = useState<{
    [key: string]: Tenant;
}|undefined>(undefined);

  const [injectConfig, setInjectConfig] = useState<
    RetorikMainComponentConfiguration | undefined
  >(undefined)
  
  const [retorikMap, setRetorikMap] = useState<JSX.Element | undefined>(undefined);
  const [_externalComponents, setExternalComponents] = useState<any | undefined>(undefined)
  const [, setCurrentTenant] = useState<Tenant|undefined>();
  const handleClick = (activity: any): void => {
    sendActivity(activity)
  }
  const customMenu: any[] = []
  buildCustomMenu(customMenu, handleClick)

  const defaultMenuConfig : RetorikMenusConfiguration ={
    baseMenu: [
      { view: AvailableViews.home, indice: 1 },
      {
        clickHandler: (): void => console.log('Test'),
        customDisplay: 'mapDisplay',
        label: { 'fr-FR': 'Carte Interactive', 'en-US': 'Interactive map' },
        icon: <MapIcon />,
        indice: 2
      },
      { view: AvailableViews.news, indice: 3 },
      { view: AvailableViews.weather, indice: 4 },
      { view: AvailableViews.emergency, indice: 5 }
    ],
    customMenu: customMenu,
    // customMenu: customMenu,
    subMenu: [
      { view: AvailableSubViews.history, indice: 1 },
      { view: AvailableSubViews.tutorial, indice: 2 }
    ]
  }

  const defaultConfig : Configuration = 
    {
      ...defaultMenuConfig,
      isUsedOnBorne: false,
      answerpanel: true,
      subtitles: false,
      locales: {
        getDefaultFromBrowser: false
      },
      logo: {
        src: 'https://studio.retorik.ai/DemoDaviParis/media/RETORIKdavi.png'
      },
      enableTelemetry: true,
      speechRecognitionOptions: {
        enableActivationSound: true
      },
      enableConversationCookie:false,
      companyName: "Davi",
      subCompanyMessage: "Johanna répond à vos questions",
      speechSynthesisOptions: {
        sayAs: {
          common: [
            {
              text: 'DAVI The Humanizers',
              ipa: 'de.ɪvɪ ð humɑnɑ.ɪzəɻz'
            }
          ]
        }
      },
    }
  

  const defaultViewsConfig : ViewsConfiguration ={
      homeRoute: 'home',
      views: {
        home: {
          background: {
            image: 'https://studio.retorik.ai/DemoDaviParis/media/backgroundJO.jpg',
            style: 'image'
          }
        }
      }
    }

  const chatbotData: ChatbotData = {
    size: '200vh',
    height: undefined
  }

  const userData: UserData = {
    name: '',
    id: '',
    username: '',
    nom: '',
    prenom: '',
    email: '',
    token: '',
    referrer: '',
    ipaddress: ''
  }

  const ponyfillFactoryCredentials: PonyfillFactoryCredentials = {
    region: 'westeurope',
    subscriptionKey: 'f5f61ee8c7334f6ab967d007d970638b'
  }

  const eventHandle = useMemo(() => {
    return botEventHandler
  }, [])
  const addressData: AddressData = {
    tenant:"JOhan"
  }


  /*useEffect(() => {
    // Fonction pour récupérer la localisation
    const getLocation = () => {
      let defaultPos = getDefaultLocalisationForJO();
        if (navigator.geolocation && currentTenant) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                  if(checkIfGeolocationIsInParis(currentTenant, {lat:position.coords.latitude, lng:position.coords.longitude})){
                    setPosition({
                      lat: position.coords.latitude,
                      lng: position.coords.longitude
                    })
                  }
                  else{
                    setPosition(defaultPos)
                  }
                    
                },
                (_err) => {
                    console.log("ERROR")
                    setPosition(defaultPos)
                }
            );
        } else {
            console.log('La géolocalisation n\'est pas supportée par votre navigateur.');
            setPosition(defaultPos)
        }
    };

    getLocation();
}, [currentTenant]);*/

useEffect(() => {
    const handlePosition = async () => {
    
      var tenants= await getTenant();
      let selectedTenant = tenants["Johan"];
      let defaultPos = getDefaultLocalisationForJO();
      let currentPosition : {lat: number;  lng: number;} = defaultPos
      if (navigator.geolocation && selectedTenant) {
          navigator.geolocation.getCurrentPosition(
              (position) => {
                if(checkIfGeolocationIsInParis(selectedTenant, {lat:position.coords.latitude, lng:position.coords.longitude})){
                  console.log("→ location good")
                  currentPosition = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                  }
                 
                }
                else{
                  console.log("→location par defaut")
                  currentPosition = defaultPos
                }
                setPosition(currentPosition)
                  
              },
              (err) => {
                  console.log("→ ERROR", err)
                  console.log("→ location par defaut")
                  currentPosition = defaultPos
                  setPosition(currentPosition)

              }
          );
      } else {
          console.log('→ La géolocalisation n\'est pas supportée par votre navigateur.');
          setPosition(currentPosition)
        
        }
        setSelectedTenant(selectedTenant)
        setTenantList(tenants);
    }
    handlePosition();
}, []);

  useEffect(() => {
    const getTenantData = async () => {
        if(_selectedTenant && _tenantList && _position){
          console.log("Set current tenant & map")
          let mapSettings:MapSettings = {
            showFilter: [
              POIType.afaire,
              POIType.restaurant,
              POIType.avoir,
              POIType.service
            ]
          }
          setCurrentTenant(_selectedTenant);
          setRetorikMap(
            <Map
              activeTenant={_selectedTenant.name}
              tenantList={_tenantList}
              userLocation={_position}
              settings={mapSettings}
            />
          );
          let copyConfig = {...defaultConfig}
          if(_position){
            copyConfig.position = {
              latitude: _position.lat,
              longitude: _position.lng
            }
          }
          const newConfig: RetorikMainComponentConfiguration =
          {
                config: copyConfig,
                viewsConfig: defaultViewsConfig,
                ponyfillFactoryCredentials,
                chatbotData,
                addressData,
                userData,
                agentData: characters.zahra,
                customVoice:voices.multi
          }
          console.log("newConfig", newConfig)
          setInjectConfig(newConfig)
        }
        
    } 
    getTenantData();
  }, [_tenantList, _selectedTenant, _position]);

  useEffect(() => {
    if(retorikMap){
      const _externalComponents = [
        {
          component: retorikMap,
          name: "mapDisplay",
        },
      ];
      setExternalComponents(_externalComponents);
    }
  }, [retorikMap]);

  if (injectConfig && _externalComponents) {
    return (
      <>
        <RetorikAgent
          {...injectConfig}
          menuConfig={defaultMenuConfig}
          externalEventHandler={eventHandle}
          externalComponents={_externalComponents}
        />
      </>
    )
  } else {
    return <></>
  }
};

export default App;
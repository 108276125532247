import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ReactGA from "react-ga4";
import { clarity } from 'react-microsoft-clarity';

console.log("Environnement : ", process.env.NODE_ENV)
if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize("G-JD0M9KJSW3");
  ReactGA.send({ hitType: "pageview", page: "", title: "JO'Han" });
  clarity.init("mnqktvgsku");
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
